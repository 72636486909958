import Vue from "vue";
import RegistrationApi from "../../../api/registration";

export default {
    /**
     * Whether the module is namespaced or not.
     *
     * @property {boolean}
     */
    namespaced: true,

    /**
     * The base state of the module.
     *
     * Use the VuexState#create method to define your state:
     *     VuexState.create({ ... })
     *
     * The "create" static method needs one object argument, containing all property names as keys,
     * and property object as values.
     *
     * Property objects should have a "type" property. They could also have two facultative properties:
     * - "formatter", which must have the name of one of the methods of the FieldFormatter class.
     * - "default", which is the default value of the property.
     *
     * @see VuexState
     * @property {object}
     */
    state: () => ({
        _agencyState: () => ({
            id: null,
            type: null,
            name: null,
            corporateName: null,
            numberOfRegistrations: 0,
            isParticipating: null,
            numberOfParticipantsForPromotion: null,
            isEligibleForPromotion: false
        }),

        all: []
    }),

    /**
     * The getters of the module.
     *
     * @property {object}
     */
    getters: {
        find: state => id => {
            return state.all.find(a => a.id === id);
        }
    },

    /**
     * The actions of the module.
     *
     * @property {object}
     */
    actions: {
        async load({state, rootState, dispatch}) {
            const
                userId = rootState.registration.userId,
                response = await RegistrationApi.agencies(userId)
            ;

            for (const item of response.data) {
                const agency = {
                    ...state._agencyState(),
                    ...item
                };

                dispatch('add', {data: agency});
            }
        },
        add({state, commit}, {data}) {
            commit('add', {data});
        }
    },

    /**
     * The mutations of the module.
     *
     * @property {object}
     */
    mutations: {
        add(state, {data}) {
            state.all.push(data);
        }
    }
}
