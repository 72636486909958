var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.registration.isParticipating
    ? _c("div", { staticClass: "recap-block" }, [
        _c("div", { staticClass: "recap-block-header" }, [
          _c("h3", [_vm._v(_vm._s(_vm._f("trans")("form.recap.title")))]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "recap-block-content" }, [
          _c("div", { staticClass: "recap-block-container" }, [
            _c("p", { staticClass: "recap-block-date" }, [
              _vm.registration.agencyId === null ||
              _vm.registration.agencyId === undefined
                ? _c("span", {
                    domProps: {
                      innerHTML: _vm._f("trans")("form.recap.date.default", {
                        "%date%": _vm.currentDate,
                      }),
                    },
                  })
                : _c("span", {
                    domProps: {
                      innerHTML: _vm._f("trans")("form.recap.date.withAgency", {
                        "%date%": _vm.currentDate,
                        "%agency%": _vm.findAgency(_vm.registration.agencyId)
                          .name,
                      }),
                    },
                  }),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "recap-block-content" },
              [
                _vm.agency.name && _vm.displayCompleteInformation
                  ? _c("div", { staticClass: "recap-block-agency" }, [
                      _c("div", { staticClass: "recap-block-agency-wrapper" }, [
                        _c("div", { staticClass: "recap-block-agency-title" }, [
                          _c("div", { staticClass: "bold" }, [
                            _c("span", { staticClass: "recap-bold" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("trans")("form.recap.agency.title")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "recap-block-agency-complete-infos" },
                          [
                            _vm.agency.name
                              ? _c("div", {
                                  staticClass: "recap-block-agency-name",
                                  domProps: {
                                    innerHTML: _vm._f("trans")(
                                      "form.recap.agency.name",
                                      { "%name%": _vm.agency.name }
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.agency.type
                              ? _c("div", {
                                  staticClass: "recap-block-agency-type",
                                  domProps: {
                                    innerHTML: _vm._f("trans")(
                                      "form.recap.agency.type",
                                      {
                                        "%type%": _vm.trans(
                                          "entity.agency.type." +
                                            _vm.agency.type +
                                            ".label"
                                        ),
                                      }
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            _vm.agency.corporateName
                              ? _c("div", {
                                  staticClass:
                                    "recap-block-agency-corporate-name",
                                  domProps: {
                                    innerHTML: _vm._f("trans")(
                                      "form.recap.agency.corporateName",
                                      {
                                        "%corporateName%":
                                          _vm.agency.corporateName,
                                      }
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.participants.length > 0
                  ? _c("form-recap-participant")
                  : _vm._e(),
                _vm._v(" "),
                _vm.rooms.length > 0 ? _c("form-recap-room") : _vm._e(),
                _vm._v(" "),
                _vm.participants.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "recap-block-tarifaire",
                        staticStyle: { width: "100%!important" },
                      },
                      [
                        _c("h4", [_vm._v("Récapitulatif tarifaire")]),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "recap-block-tarifaire-container" },
                          [
                            _c(
                              "div",
                              { staticClass: "recap-block-tarifaire-wrapper" },
                              [
                                _vm._m(0),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "recap-block-tarifaire-complete-infos",
                                  },
                                  [
                                    _vm.getPartyPackPrice({
                                      formatted: false,
                                    }) > 0
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "recap-bold" },
                                            [
                                              _vm._v(
                                                "\n                                            Pack sans hébergement (" +
                                                  _vm._s(
                                                    _vm.getPartyPackPrice({
                                                      unit: true,
                                                    })
                                                  ) +
                                                  "\n                                            × " +
                                                  _vm._s(
                                                    _vm.countParticipantsWithPartyPack()
                                                  ) +
                                                  ") :\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(
                                            "\n\n                                        " +
                                              _vm._s(_vm.getPartyPackPrice()) +
                                              "\n                                    "
                                          ),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.rooms.length > 0
                                      ? _c(
                                          "div",
                                          _vm._l(_vm.rooms, function (room) {
                                            return _c("div", [
                                              _c(
                                                "span",
                                                { staticClass: "recap-bold" },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm._f("replace")(
                                                        _vm._f("trans")(
                                                          "form.recap.hotel." +
                                                            _vm.findHotelById(
                                                              room.hotelId
                                                            ).uid +
                                                            ".label",
                                                          {
                                                            "%price%":
                                                              _vm.getPriceNet(
                                                                room.hotelId,
                                                                room.roomTypeId
                                                              ),
                                                            "%roomType%":
                                                              _vm.getRoomType(
                                                                room
                                                              ),
                                                          }
                                                        ),
                                                        /\sTTC/,
                                                        ""
                                                      )
                                                    ) +
                                                      " :\n                                            "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.getPriceRoom_oneRoom(
                                                      room.hotelId,
                                                      room.roomTypeId
                                                    ) *
                                                      room.occupantsUidx.length
                                                  ) +
                                                    "€\n                                            "
                                                ),
                                              ]),
                                            ])
                                          }),
                                          0
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.countInsurances > 0
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "recap-bold" },
                                            [
                                              _vm._v(
                                                "\n                                            Souscription à l'assurance\n                                            (" +
                                                  _vm._s(
                                                    _vm.getInsurancePrice(
                                                      true,
                                                      true
                                                    )
                                                  ) +
                                                  " × " +
                                                  _vm._s(_vm.countInsurances) +
                                                  ") :\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getInsurancePrice(
                                                  false,
                                                  true
                                                )
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isPromotionApplied()
                                      ? _c("div", [
                                          _c(
                                            "span",
                                            { staticClass: "recap-bold" },
                                            [
                                              _vm._v(
                                                "\n                                            Remise exceptionnelle\n                                            (" +
                                                  _vm._s(
                                                    _vm.getPromoPrice(
                                                      true,
                                                      true
                                                    )
                                                  ) +
                                                  " × " +
                                                  _vm._s(
                                                    _vm.getNumberOfPromotionsApplied()
                                                  ) +
                                                  ") :\n                                        "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.getPromoPrice(false, true)
                                              )
                                            ),
                                          ]),
                                        ])
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("br"),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "recap-block-tarifaire-total-price",
                                      },
                                      [
                                        _vm.getTotalPrice({
                                          formatted: false,
                                        }) > 0
                                          ? _c(
                                              "div",
                                              { staticClass: "total-price" },
                                              [
                                                _c("h5", [
                                                  _vm._v("Prix total"),
                                                ]),
                                                _vm._v(" "),
                                                _c("div", [
                                                  _c("b", [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.getTotalPrice({
                                                          rooms: _vm.rooms,
                                                        })
                                                      ) + " NET"
                                                    ),
                                                  ]),
                                                ]),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isDeferredSelected()
                  ? _c("div", { staticClass: "recap-block-echeancier" }, [
                      _c("h4", [_vm._v("Echéancier")]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "recap-block-echeancier-container" },
                        [
                          _c(
                            "div",
                            { staticClass: "recap-block-echeancier-wrapper" },
                            [
                              _vm._m(1),
                              _vm._v(" "),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "recap-block-tarifaire-complete-infos",
                                },
                                [
                                  _c(
                                    "table",
                                    _vm._l(
                                      _vm.getAmountOfPayments(),
                                      function (n) {
                                        return _c("tr", [
                                          _c(
                                            "td",
                                            { staticClass: "recap-bold" },
                                            [
                                              _vm._v(
                                                "\n                                                Echéance n°" +
                                                  _vm._s(n) +
                                                  "\n                                            "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.getDeferredMonth(n))
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(_vm.getFracPrices()[n - 1])
                                            ),
                                          ]),
                                        ])
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "recap-block-tarifaire-title" }, [
      _c("div", { staticClass: "bold" }, [
        _c("span", { staticClass: "recap-bold" }, [_vm._v("Détails")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "recap-block-echeancier-title" }, [
      _c("div", { staticClass: "bold" }, [
        _c("span", { staticClass: "recap-bold" }, [_vm._v("Détails")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }